import React from "react"
import { Link } from "gatsby"
import slugify from "slugify"
import "../assets/css/global.css"

const WinningFilmsList = ({ films = [] }) => {
  return (
    <div className="winning-list grid-cols-1" style={{marginTop: "25px", gap: "1rem"}}>
      {films.map(film => {
        const { id, title, genre, universityCollege, videoUrl } = film
        const slug = slugify(title, { lower: true })
        return (
          <Link key={id} to={`/${slug}/`} className="recipe" style={{height: "60%"}}>
            <img alt="Film thumbnail" srcSet={`https://vimeo-thumbnail-server-two.vercel.app/${videoUrl.providerUid}.jpg 640w, https://vimeo-thumbnail-server-two.vercel.app/${videoUrl.providerUid}_large.jpg 640w, https://vimeo-thumbnail-server-two.vercel.app/${videoUrl.providerUid}_medium.jpg 200w, https://vimeo-thumbnail-server-two.vercel.app/${videoUrl.providerUid}_small.jpg 100w" sizes="(max-width: 640px) 100vw, 640px" src="https://vimeo-thumbnail-server-two.vercel.app/${videoUrl.providerUid}.jpg" alt="Vimeo Thumbnail`} />
            <p style={{textTransform: "uppercase", fontSize: "12px", fontWeight: "600", lineHeight: "15px", letterSpacing: "0.1em", marginBottom: "10px"}}>{genre}</p>
            <h4 style={{fontWeight: "600"}}>{title}</h4>
            <p style={{fontSize: "20px", fontWeight: "300", lineHeight: "24px"}}>{universityCollege}</p>
          </Link>
        )
      })}
    </div>
  )
}

export default WinningFilmsList