import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import AboutSection from "../components/AboutSection"
import FilmFestivalSection from "../components/FilmFestivalSection"
import FilmLibrarySection from "../components/FilmLibrarySection"
import CurrentMonthWinner from "../components/CurrentMonthWinner"
import WinningFilms from "../components/WinningFilms"
import "../assets/css/global.css"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import { Helmet } from 'react-helmet'
import LoadMore from "../assets/images/Load-more-arrow.svg"
import { Link } from "gatsby"

const settings = {
  dots: true,
  lazyLoad: false,
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}
const d = new Date();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const winnerCurrentMonth = months[d.getMonth() - 2]
const nomineeCurrentMonth = months[d.getMonth() - 1]



export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Film Chronicle</title>
        <link rel="canonical" href="https://filmchronicle.com/" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      </Helmet>
      <Seo title="Film Chronicle" description="By film students, for film students." image="../assets/images/Home-Hero.png" />
      <header className="hero">
        <StaticImage
          src="../assets/images/Home-Hero.png"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container dark-overlay">
          <div className="hero-text">
            <h1>A More innovative
Student Film Industry Is Possible
and it Starts with the Students</h1>
            <Link to="/submissions/monthly-festival"><button className="btn btn-white center-btn">Submit Film</button></Link>
          </div>
        </div>
      </header>
      <div className="container-lg">
        <div className="pb-50" style={{textAlign: "center"}}>
            <div>
              <h2 style={{fontSize: "60px"}}>{winnerCurrentMonth} {new Date().getFullYear()} Winner!</h2>
              {/* <p style={{fontSize: "20px"}}>Voted best film by the student judges for the month of {winnerCurrentMonth}</p> */}
            </div>
        </div>
      </div>
      <CurrentMonthWinner month={winnerCurrentMonth}/>
      <div className="container-lg">
        <div className="section grid-cols-1">
            <div>
              <h2 style={{fontSize: "30px"}}>{nomineeCurrentMonth} {new Date().getFullYear()} Nominees</h2>
              <p style={{fontSize: "20px"}}>Top user upvoted films in {nomineeCurrentMonth}</p>
            </div>
        </div>
      </div>
      <WinningFilms/>
      <AboutSection/>
      <FilmFestivalSection/>
      <Slider {...settings}>
        <div style={{position: "relative"}}>
          {/* <p style={{position: "absolute", top: "10%", left: "30%", color: "#fff", zIndex: "6000", maxWidth: "400px"}}>Whether you’re looking to break into the industry, seeking a crew, or just filming for fun—Film Chronicle welcomes all storytellers.</p> */}
          <StaticImage
              src="../assets/images/slides/Home-Slider-1.png"
              alt="film director"
              className="hero-img"
              placeholder="tracedSVG"
              layout="fullWidth"
            ></StaticImage>
        </div>
        <div style={{position: "relative"}}>
          {/* <p style={{position: "absolute", top: "10%", left: "44%", color: "#fff", zIndex: "6000", maxWidth: "400px"}}>Let’s do this together, proudly with good intention. The Film Chronicle team is as dedicated as ever to give your films life, whether through a festival or the library.</p> */}
          <StaticImage
              src="../assets/images/slides/Home-Slider-2.png"
              alt="film director"
              className="hero-img"
              placeholder="tracedSVG"
              layout="fullWidth"
            ></StaticImage>
        </div>
        <div style={{position: "relative"}}>
          {/* <p style={{position: "absolute", top: "10%", left: "16%", color: "#fff", zIndex: "1000", maxWidth: "400px"}}>Our team knows how difficult it is to have a student film recognized. We were tired of the excuses&mdash;low budget, too young, maybe next time, so we thought why not shake things up.</p> */}
          <StaticImage
              src="../assets/images/slides/Home-Slider-3.png"
              alt="film director"
              className="hero-img"
              placeholder="tracedSVG"
              layout="fullWidth"
            ></StaticImage>
        </div>
      </Slider>
      <FilmLibrarySection/>
      <div className="container">
        <div className="grid-cols-3">
          <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem", display: "none"}}>No more results</p>
            <a style={{gridColumnStart: "6"}} href="#top">
              <img
                src={LoadMore}
                alt="flour and eggs"
                placeholder="tracedSVG"
                layout="constrained"
                // quality="90"
              />
            </a>
        </div>
      </div>
    </Layout>
  )
}
