import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "../assets/css/global.css"
import slugify from "slugify"


const query = graphql`
  {
    datoCmsVideo(monthlyWinner: {eq: true}) {
        id
        genre
        videoUrl {
          height
          provider
          providerUid
          thumbnailUrl
          title
          url
          width
        }
        title
        universityCollege
        director
        meta {
          publishedAt
        }
      }
  }
`

const CurrentMonthWinner = ({currentMonth}) => {
  const data = useStaticQuery(query)
  console.log(data.datoCmsVideo.title)
  const slug = slugify(data.datoCmsVideo.title, { lower: true })
  return (
    <section className="container-lg" style={{marginBottom: "2rem"}}>
      <Link key={data.datoCmsVideo.id} to={`/${slug}/`} className="recipe">
            <img alt="Film thumbnail" srcSet={`https://vimeo-thumbnail-server-two.vercel.app/${data.datoCmsVideo.videoUrl.providerUid}.jpg 640w, https://vimeo-thumbnail-server-two.vercel.app/${data.datoCmsVideo.videoUrl.providerUid}_large.jpg 640w, https://vimeo-thumbnail-server-two.vercel.app/${data.datoCmsVideo.videoUrl.providerUid}_medium.jpg 200w, https://vimeo-thumbnail-server-two.vercel.app/${data.datoCmsVideo.videoUrl.providerUid}_small.jpg 100w" sizes="(max-width: 640px) 100vw, 640px" src="https://vimeo-thumbnail-server-two.vercel.app/${data.datoCmsVideo.videoUrl.providerUid}.jpg" alt="Vimeo Thumbnail`} />
            <p style={{textTransform: "uppercase", fontSize: "16px", fontWeight: "600", lineHeight: "15px", letterSpacing: "0.1em", marginBottom: "10px", textAlign: "center"}}>{data.datoCmsVideo.genre}</p>
            <h4 style={{fontWeight: "600", textAlign: "center", fontSize: "40px"}}>{data.datoCmsVideo.title}</h4>
            <p style={{fontSize: "20px", fontWeight: "300", lineHeight: "32px", textAlign: "center"}}>{data.datoCmsVideo.director}, {data.datoCmsVideo.universityCollege}</p>
            <p style={{textAlign: "center"}}>Read Q&amp;A with the director &rarr;</p>
          </Link>
    </section>
  )
}

export default CurrentMonthWinner