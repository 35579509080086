import React from "react"
import { Link } from "gatsby"
import "../assets/css/global.css"

const AboutSection = () => {
    return (
      <section className="py-100">
        <div className="container-lg">
            <h2 className="text-center pb-50">Discover Your Future Cast &amp; Crew</h2>
            <div className="section grid-cols-1">
                <div>
                    <h4 style={{marginBottom: "1rem", fontWeight: "600"}}>What's Film Chronicle?</h4>
                    <p style={{fontWeight: "300"}}>A meeting point for creatives to cultivate and promote current, past, and future film students in the media industry. The platform is designed for the students by students, where aspiring filmmakers can find inspiration, impart knowledge and experience, connect, and share unbiased critiques.</p>
                </div>
                <div>
                    <h4 style={{marginBottom: "1rem", fontWeight: "600"}}>Why participate?</h4>
                    <p style={{fontWeight: "300"}}>It’s a stress free, happy environment for the student community. We offer a film library for past films and a monthly film festival dedicated to enrolled students to showcase their work with honors going to the best film. Let’s help each other break into the film industry, sell scripts, and fund our projects.</p>
                </div>
            </div>
            <Link to="/about/company"><button className="btn btn-black center-btn" style={{padding: "2rem"}}>About us</button></Link>
        </div>
      </section>
    )
  }
  
  export default AboutSection