import React from "react"
import WinningFilmsList from "./WinningFilmsList"
import { graphql, useStaticQuery } from "gatsby"
import "../assets/css/global.css"

const query = graphql`
  {
    allDatoCmsVideo(filter: {nominee: {eq: true}}, sort: {fields: meta___publishedAt, order: DESC}) {
      nodes {
        id
        title
        genre
        universityCollege
        length
        videoUrl {
          url
          providerUid
        }
      }
    }
  }
`

const WinningFilms = () => {
  const data = useStaticQuery(query)
  const films = data.allDatoCmsVideo.nodes
  return (
    <section className="container-lg">
      <WinningFilmsList films={films} />
    </section>
  )
}

export default WinningFilms