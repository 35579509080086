import React from "react"
import { Link } from "gatsby"
import "../assets/css/global.css"

const FilmLibrarySection = () => {
    return (
      <section className="py-50 pb-50">
        <div className="container-lg">
            <h2 className="text-center pt-50 pb-50">Enjoy Past and Present Work in Our Film Library</h2>
            <div className="section grid-cols-1">
                <div>
                    <h4 style={{marginBottom: "1rem"}}>Submit, or watch &amp; marvel</h4>
                    <p style={{fontWeight: "300"}}>Though only college student shorts can be submitted for competition in the main festival, non-college students can submit the work to be viewed on our site. Feel free to watch the shorts and vote for your favorites.</p>
                </div>
                <div>
                    <h4 style={{marginBottom: "1rem"}}>Breaking down school walls</h4>
                    <p style={{fontWeight: "300"}}>As a festival, we are looking to ensure that the next generation of filmmakers can successfully bring their visions to life regardless of where they go to school. We welcome all film majors, from those who attend film schools across the board.</p>
                </div>
            </div>
            <Link to="/watch/film-library"><button className="btn btn-black center-btn" style={{padding: "2rem"}}>Film Library</button></Link>
        </div>
      </section>
    )
  }
  
  export default FilmLibrarySection