import React from "react"
import { Link } from "gatsby"
import "../assets/css/global.css"

const FilmFestivalSection = () => {
    return (
      <section className="py-50">
        <div className="container-lg">
            <h2 className="text-center pb-50">Your Film Takes Front Page, Voted By The Students</h2>
            <div className="section grid-cols-1">
                <div>
                    <h4 style={{marginBottom: "1rem"}}>What needs to change?</h4>
                    <p style={{fontWeight: "300"}}>At Film Chronicle, we believe the student community needs to come together. Our current system has sheltered us from the indie and rising film stars. As a result, there are too many student films gone unnoticed. The goal is to break the cycle and give students the fame earlier on in their careers.</p>
                </div>
                <div>
                    <h4 style={{marginBottom: "1rem"}}>Designing a new platform</h4>
                    <p style={{fontWeight: "300"}}>By offering a 100% free service for students at any school to participate and know their films will circulate among other students–we’re able to commune together without having to jump through corporate hoops and have our works of art reach a wider audience.</p>
                </div>
            </div>
            <Link to="/watch/monthly-festival"><button className="btn btn-black center-btn" style={{padding: "2rem"}}>Film Festival</button></Link>
        </div>
      </section>
    )
  }
  
  export default FilmFestivalSection